import {Outlet, useNavigate} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Group from '@mui/icons-material/Group'
import HowToReg from '@mui/icons-material/HowToReg';
import Box from '@mui/material/Box';
import React, {useState} from "react";

import imgBackground from "../assets/images/img.png";
import useAuth from "../hooks/useAuth";

const Root = () => {
    const {isAuthenticated, user} = useAuth()
    const navigate = useNavigate()
    const [state, setState] = useState<boolean>()

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setState(open)
        }

    const list = () => (
        <Box
            sx={{width: 250}}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate('guests')}>
                        <ListItemIcon>
                            <Group/>
                        </ListItemIcon>
                        <ListItemText primary="Lista de convidados"/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate('check-ins')}>
                        <ListItemIcon>
                            <HowToReg/>
                        </ListItemIcon>
                        <ListItemText primary="Check-ins"/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    )

    return (
        <div className="pt-14">
            <AppBar position="fixed" style={{backgroundImage: `url(${imgBackground})`}}>
                <Toolbar>

                    {isAuthenticated && (user?.email === 'mail.weverton@gmail.com' || user?.email === 'flor.sergio@gmail.com') && (
                        <IconButton edge="start" color="inherit" aria-label="menu" sx={{mr: 2}} className="z-60"
                                    onClick={toggleDrawer(true)}>
                            <MenuIcon/>
                        </IconButton>
                    )}

                    <div color="inherit" className="flex items-center justify-center w-full absolute">
                        <svg xmlns="http://www.w3.org/2000/svg" id="uuid-3ce109b9-8937-4886-9903-4845a893bf34"
                             data-name="Camada 2" viewBox="0 0 198.96 106.08" className="h-10 -ml-10" onClick={() => navigate('/')}>
                            
                            <g id="uuid-58eaffd2-e932-4166-be1b-44c621e511ee" data-name="Camada 1">
                                <g>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m38.77,59.81c-.09.32-.22.67-.4,1.04-.17.36-.43.7-.75,1.01-.78.74-1.86,1.12-3.23,1.12-.16,0-.38-.02-.63-.05-.26-.04-.54-.11-.84-.23-.3-.12-.61-.29-.92-.51-.3-.22-.58-.52-.83-.91-.25-.39-.46-.88-.61-1.45-.16-.58-.23-1.29-.23-2.1,0-.19-.06-.35-.18-.47-.12-.12-.29-.19-.49-.19-.17,0-.32.06-.45.19-.13.12-.2.28-.2.47,0,1.13.17,2.18.51,3.12.32.82.77,1.51,1.32,2.05.31.31.64.56.99.74.34.18.66.31.98.41.31.09.61.16.89.19.29.03.51.05.7.05.86,0,1.64-.12,2.34-.37.69-.25,1.31-.62,1.82-1.12.42-.4.75-.85.98-1.32.23-.47.4-.91.51-1.31.11-.41.17-.74.2-1.01.02-.27.03-.42.03-.46v-10.45c0-.17-.06-.32-.18-.45-.12-.12-.28-.19-.46-.19h-5.08c-.19,0-.35.06-.47.19-.12.12-.19.27-.19.45,0,.19.06.35.19.47.12.12.28.19.47.19h4.41v9.79c-.03.44-.09.83-.18,1.14Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m47.13,47.66c-.28-.26-.69-.24-.93,0-.12.12-.19.27-.19.45v12.34s.01.17.1.65c.07.38.3.79.68,1.23.22.27.48.5.78.71.3.2.63.38.98.54.89.34,1.96.51,3.16.51s2.27-.17,3.17-.51c.35-.15.68-.33.98-.54.3-.2.56-.44.78-.71.19-.22.34-.44.44-.65.1-.21.18-.4.23-.57.06-.17.09-.32.1-.44,0-.11.01-.18.01-.21v-12.34c0-.17-.06-.32-.19-.45-.25-.25-.69-.25-.94,0-.12.12-.19.28-.19.45v12.34s0,.07-.08.38c-.05.22-.2.47-.46.76-.71.8-2.01,1.2-3.85,1.2s-3.14-.41-3.85-1.21c-.26-.29-.41-.54-.46-.76-.07-.32-.08-.38-.08-.38v-12.34c0-.17-.07-.33-.2-.45Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m64.62,47.84c-.24-.25-.67-.28-.94,0-.12.13-.19.28-.19.43v15.21c0,.17.06.33.19.45.13.12.28.19.46.19h9.47c.16,0,.3-.06.43-.19.13-.13.2-.28.2-.46s-.07-.33-.2-.46c-.13-.12-.28-.19-.43-.19h-8.8v-14.55c0-.16-.06-.3-.19-.43Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m77.69,47.7c-.12.13-.18.28-.18.43v15.36c0,.16.06.3.18.43.12.13.28.2.47.2.2,0,.37-.07.49-.2.12-.13.18-.28.18-.43v-15.36c0-.15-.06-.3-.18-.43-.12-.13-.29-.2-.49-.2-.19,0-.35.07-.47.2Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m89.99,47.52c-.3,0-.51.15-.6.43l-5.77,15.36c-.08.17-.07.35.02.51.08.15.21.26.38.33.07.02.14.04.21.04.09,0,.19-.02.28-.06.17-.08.29-.2.34-.37l1.48-3.92,8.45,2.94.38.97c.04.16.12.27.25.35.21.12.5.11.6.06.17-.07.29-.19.36-.34.08-.15.09-.32.03-.49l-5.77-15.36c-.09-.28-.31-.43-.63-.43Zm4.2,13.66l-7.41-2.56,3.21-8.58,4.2,11.14Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m121.19,48.72c.17,0,.32-.06.46-.18.13-.12.2-.28.2-.47,0-.17-.07-.33-.2-.45-.13-.12-.28-.18-.46-.18h-10c-.19,0-.35.06-.47.19s-.19.28-.19.45v15.43c0,.17.06.32.19.45s.28.19.47.19c.17,0,.33-.06.46-.18.13-.12.2-.28.2-.45v-7.73h9.34c.17,0,.33-.07.46-.2s.2-.29.2-.46-.07-.33-.2-.45h0c-.13-.12-.28-.18-.46-.18h-9.34v-5.75h9.34Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m125.94,47.84c-.24-.25-.67-.28-.94,0-.12.13-.19.28-.19.43v15.21c0,.17.06.33.19.45.13.12.28.19.46.19h9.47c.16,0,.3-.06.43-.19.13-.13.2-.28.2-.46s-.07-.33-.2-.46c-.13-.12-.28-.19-.43-.19h-8.8v-14.55c0-.16-.06-.3-.19-.43Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m138.12,52.4c-.43,1.01-.65,2.11-.65,3.26s.22,2.25.65,3.26c.44,1.01,1.05,1.9,1.8,2.65.77.77,1.66,1.37,2.66,1.78,1.06.44,2.15.67,3.26.67s2.21-.23,3.23-.67c.98-.4,1.88-1,2.66-1.78.77-.75,1.37-1.64,1.8-2.66.43-1.01.65-2.11.65-3.26s-.22-2.25-.65-3.26c-.41-.98-1.02-1.88-1.8-2.66-.78-.78-1.68-1.38-2.66-1.78-2.04-.88-4.36-.9-6.49,0-1,.41-1.89,1.01-2.66,1.78-.77.78-1.37,1.67-1.8,2.66Zm7.72-3.81c.96,0,1.88.19,2.73.56.85.37,1.6.88,2.23,1.52.63.64,1.14,1.4,1.51,2.25.37.86.56,1.78.56,2.74s-.19,1.88-.56,2.74c-.37.85-.88,1.61-1.51,2.25-.63.64-1.38,1.15-2.23,1.52-.85.37-1.77.56-2.73.56s-1.88-.19-2.73-.56c-.85-.37-1.61-.88-2.24-1.52-.64-.64-1.15-1.39-1.52-2.25-.37-.85-.56-1.77-.56-2.74s.19-1.89.56-2.74c.37-.86.88-1.61,1.52-2.25.64-.64,1.39-1.15,2.24-1.52.85-.37,1.77-.56,2.73-.56Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m167.17,56.12c.56-.42,1.02-.96,1.37-1.61.34-.62.52-1.29.52-2s-.17-1.37-.52-2c-.35-.62-.82-1.16-1.37-1.61-.58-.46-1.26-.82-2-1.06-.74-.24-1.53-.36-2.34-.36l-2.81-.05c-.13,0-.27.05-.41.15l-.11.03v.02h-.02v.09c-.08.09-.11.21-.11.35v15.34c0,.17.06.32.19.45.25.25.69.25.94,0,.12-.12.19-.27.19-.45v-5.84l2.15-.02c.12,0,.25,0,.38-.01.13,0,.26-.02.37-.03.02,0,.09,0,.28.04.14.03.33.12.56.27.24.15.52.39.84.7.31.31.66.75,1.01,1.32.27.44.55.89.84,1.34.29.45.55.87.78,1.25.23.38.5.82.8,1.31.11.21.31.32.57.32.11,0,.22-.03.35-.09.15-.09.25-.22.3-.38.05-.17.02-.33-.07-.48l-.81-1.33c-.23-.39-.5-.81-.78-1.26-.29-.46-.58-.9-.86-1.34-.37-.58-.72-1.06-1.06-1.41-.26-.28-.51-.51-.75-.71.25-.1.5-.22.75-.35.31-.17.6-.37.85-.58Zm.57-3.61c0,.46-.11.9-.33,1.32-.22.42-.52.8-.89,1.13-.37.33-.83.61-1.35.84-.52.23-1.09.37-1.66.43h-.13c-.09.02-.18.03-.27.03-.09,0-.19.01-.27.01l-2.16.02v-7.54l2.16.04c.68,0,1.32.1,1.92.3.59.2,1.12.47,1.56.81.44.34.79.73,1.04,1.18.25.45.38.93.38,1.43Z"/>
                                    <polygon fill="#fff" strokeWidth="0px"
                                             points="146.3 43.13 148.38 45.77 148.41 45.81 150.28 45.81 147.02 41.94 146.98 41.9 145.61 41.9 142.33 45.81 144.24 45.81 146.3 43.13"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m91.34,27.38c1.08.58,2.25.87,3.45.87,1.33,0,2.68-.35,3.97-1.06,2.5-1.37,3.56-3.32,3.8-6.93.21-3.14.17-8.49.14-12.79,0-1.27-.02-2.46-.02-3.49,0-.14,0-.28,0-.43-.01-.68-.03-1.46.24-1.9.21-.34.75-.66,1.32-.33.69.41.63,2.2.59,3.5-.01.32-.02.63-.02.91,0,.36,0,.83.02,1.35.03,1.27.06,2.82-.03,3.69-.35,0-.61-.01-.82-.02-.36-.02-.58-.03-.75.12-.15.14-.18.35-.19.59v.16s.12.09.12.09c.34.24.87.21,1.38.18.09,0,.18,0,.26-.01,0,.44,0,.89.01,1.35.02,2.1.05,4.47-.21,6.39-.24,1.8-.96,4.76-1.94,5.51l-.62.48.78.03c1.18.06,3.08-1.18,3.76-1.74,3.22-2.67,3.12-7.18,3.01-11.96,0-.08,0-.17,0-.25l.1.02s1.44.31,2.13,1.37l.28.44.21-.47s.41-.87,1.51-1.36l.58-.26-.58-.26c-1.09-.48-1.5-1.35-1.51-1.36l-.22-.47-.28.43c-.69,1.07-2.12,1.37-2.13,1.37l-.11.02c-.01-.6-.03-1.2-.03-1.81V3.39c0-1.06,0-1.7.29-1.97.28-.26.91-.33,2.32-.24,2.2.14,3.03,1.69,3.64,4.15.17.68.22,1.1.26,1.39.08.64.19.75.82.75h.47s-.01-.29-.01-.29c-.11-2.34-.04-4.43.1-6.86l.02-.3h-20.05s-.59-.01-.59-.01c-.52,0-.63-.01-.75.12l-.1.23.02.13-.06.2.13.11c.06.22.27.26.66.34l.23.04c.46.08.68.12.85.69.24.8.22,7.47.12,8.91-.34,0-.67-.01-.98-.02-.92-.02-1.73-.03-2.49.02-.41-.21-.85-.53-1.16-1l-.28-.43-.22.47s-.42.87-1.51,1.36l-.58.26.58.26c-.41.23-.82.5-1.25.86-1.62,1.34-3.26,4.1-2.97,7.84.24,3.07,1.83,5.69,4.27,7Zm.93-15.04c.37.36.53.69.53.7l.22.47.28-.43c.45-.7,1.21-1.06,1.69-1.24.29-.01.59-.01.89-.01.47,0,.94,0,1.38.02.24,0,.48,0,.7.01,0,.41.01.89.02,1.41.06,3.6.15,9.62-.52,11.71-.59,1.82-1.6,2.4-3.7,2.14-1.66-.21-3.07-1.28-3.83-2.21-1.7-2.08-2.3-5.06-1.57-7.77.59-2.22,2.02-3.94,3.93-4.79Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m114.24,26.95c-.13.2-2.8,4.02-3.52,4.77-.14.15-.18.36-.1.55.08.18.26.3.46.3h17.46c.2,0,.38-.12.46-.31,0-.02.97-2.21,3.07-4.53h10.99c-.03.07-.05.13-.08.2-.24.74-.38,2.19,1.39,3.6,1.35,1.07,3.29,1.37,5.1.78,1.84-.62,3.22-2.04,3.69-3.82.06-.25.09-.51.09-.76h2.7c3.13,2.87,7.07,4.58,10.55,4.58.83,0,1.63-.09,2.37-.28.52-.13,1.03-.3,1.54-.52.56-.07,3.12-.44,6.31-1.87,3.33-1.49,6.05-.35,6.62.21.46.46.65,1.17.43,1.56-.24.43-.96.32-1.36.21-.13-.03-.27-.01-.38.05-.11.07-.2.18-.23.3-.03.13-.02.26.05.38s.18.2.31.23c.33.08.63.13.91.13.96,0,1.39-.51,1.57-.81.45-.79.2-1.98-.59-2.77-.6-.6-2.1-1.24-3.99-1.24-1.27,0-2.53.28-3.74.82-1.36.61-2.6,1.01-3.59,1.28.6-.56,1.09-1.21,1.44-1.91.06-.12.11-.24.16-.37h18.62c.53,0,1.03.25,1.32.67.46.65,1.24.99,2.04.86.86-.13,1.54-.79,1.71-1.64.12-.62-.03-1.26-.43-1.74-.4-.49-.99-.77-1.61-.77-.7,0-1.35.35-1.74.94-.28.42-.79.68-1.33.68h-18.29c.1-.62.05-1.19-.16-1.66-.51-1.15-1.67-2.51-3.09-2.95-.91-.28-1.83-.15-2.67.37-1.14.71-1.28,1.58-1.25,2.04.04.7.48,1.37,1.1,1.66.43.2.87.18,1.21-.06.77-.56.44-1.37.29-1.76-.06-.14-.17-.43-.13-.49.37-.52.57-.71,1.29-.76,1.06.38,1.94,1.45,2.34,2.35.14.32.16.76.06,1.25h-17.23c-3.43-3.34-7.79-4.21-8.24-4.29-3.02-1.19-6.48-1.29-9.49-.28-2.59.87-4.94,2.41-6.98,4.56h-16.97c-.16,0-.31.08-.42.22Zm-2.1,4.62c.5-.64,1.35-1.79,2.78-3.84h15.82c-1.36,1.64-2.18,3.14-2.53,3.84h-16.07Zm33.19-2.38c.83-.02,1.17-.44,1.3-.68.12-.22.17-.49.14-.77h5.48c0,.18-.01.35-.06.51-.38,1.45-1.52,2.61-3.04,3.12-1.49.5-3.04.27-4.16-.61-.41-.32-.71-.66-.9-1.02,0-.42.05-.51,1.24-.54Zm1.01-2.45c-.35-.42-.83-.67-1.31-.64-.44.02-.9.25-1.3.64h-10.68c1.76-1.67,3.74-2.89,5.89-3.62,4.64-1.56,9.52-.09,11.94,2.17.5.47.88.96,1.11,1.45h-5.65Zm8.51,0h-1.77c-.22-.66-.65-1.31-1.27-1.94,1.13.55,2.15,1.2,3.04,1.94Zm2.63,1h15.74c-.41.81-1.63,2.59-4.59,3.33-3.37.84-7.59-.43-11.15-3.33Zm37.39-.53c0-.6.49-1.08,1.09-1.08s1.08.49,1.08,1.08-.49,1.08-1.08,1.08-1.09-.49-1.09-1.08Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m2.68,29.26c.79.12,1.58-.21,2.04-.86.29-.42.79-.67,1.32-.67h18.62c.05.12.1.25.16.37.34.7.83,1.34,1.43,1.91-.99-.27-2.23-.67-3.59-1.28-1.21-.54-2.47-.82-3.74-.82-1.89,0-3.4.64-3.99,1.24-.79.79-1.04,1.98-.59,2.77.17.3.61.81,1.57.81.28,0,.59-.04.91-.13.13-.03.24-.11.31-.23.07-.12.09-.25.05-.38-.03-.13-.11-.24-.23-.31-.11-.07-.25-.09-.38-.05-.4.1-1.12.21-1.36-.21-.22-.39-.02-1.11.43-1.56.56-.56,3.29-1.7,6.62-.22,3.19,1.43,5.75,1.8,6.31,1.87.51.22,1.02.39,1.54.52.74.19,1.54.28,2.37.28,3.48,0,7.42-1.71,10.55-4.58h2.7c0,.25.03.51.09.76.47,1.77,1.85,3.2,3.69,3.82,1.8.59,3.75.29,5.1-.78,1.77-1.41,1.63-2.86,1.39-3.6-.02-.07-.05-.13-.07-.2h10.99c2.11,2.33,3.06,4.51,3.07,4.54.08.18.26.3.46.3h17.46c.2,0,.38-.12.46-.31.08-.19.04-.4-.11-.54-.73-.74-3.39-4.57-3.53-4.78-.1-.13-.25-.21-.41-.21h-16.97c-2.04-2.15-4.39-3.69-6.98-4.56-3-1.01-6.45-.92-9.47.27-.59.11-4.88,1-8.26,4.29h-17.23c-.1-.49-.08-.93.06-1.25.4-.9,1.28-1.97,2.35-2.35.72.05.92.24,1.29.76.05.07-.07.36-.13.5-.16.39-.49,1.2.29,1.76.34.24.78.26,1.21.06.61-.3,1.05-.96,1.1-1.67.02-.45-.11-1.32-1.25-2.04-.84-.52-1.76-.65-2.67-.37-1.42.44-2.58,1.79-3.09,2.95-.21.46-.26,1.03-.16,1.66H6.08c-.55,0-1.06-.26-1.33-.68-.39-.59-1.04-.95-1.74-.95-.63,0-1.21.28-1.61.77-.4.49-.55,1.12-.43,1.74.17.84.85,1.5,1.71,1.64Zm.33-3.14c.6,0,1.09.49,1.09,1.08s-.49,1.08-1.09,1.08-1.08-.49-1.08-1.08.49-1.08,1.08-1.08Zm22.74,1.62h15.75c-3.56,2.9-7.79,4.17-11.15,3.33-2.96-.74-4.18-2.53-4.59-3.33Zm18.38-1c.9-.74,1.91-1.39,3.04-1.94-.62.63-1.04,1.28-1.27,1.94h-1.77Zm8.08,1c-.03.28.02.55.14.77.13.25.47.66,1.3.69,1.18.03,1.23.13,1.24.54-.19.35-.49.69-.9,1.02-1.12.88-2.67,1.11-4.16.61-1.52-.51-2.66-1.67-3.04-3.12-.04-.16-.06-.33-.06-.51h5.48Zm-5.22-1c.23-.48.61-.98,1.11-1.45,2.42-2.26,7.3-3.73,11.94-2.17,2.15.73,4.13,1.94,5.89,3.62h-10.68c-.4-.39-.87-.62-1.3-.64-.48-.03-.96.22-1.31.64h-5.65Zm21.24,1h15.82c.48.69,1.84,2.63,2.78,3.84h-16.07c-.35-.7-1.16-2.19-2.53-3.84Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m25.42,69.19c.13-.37.06-.73-.2-.99-.22-.21-.71-.47-1.55.17-.31.24-2.4,2.31-2,3.01.09.16.29.26.53.26h0c.5-.04,2.84-1.38,3.22-2.46Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m20.02,66.83c-.42.49-.31,1.29-.13,2.28l.06.36v.04c.24,1.3.32,1.74.85,1.74s.64-.4.96-2.25c.16-.91.25-1.7-.15-2.18-.11-.13-.36-.34-.79-.34s-.66.19-.79.35Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m20.81,71.63c-.61,0-1.1.49-1.1,1.1s.5,1.11,1.1,1.11,1.1-.5,1.1-1.11-.49-1.1-1.1-1.1Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m19.92,71.38c.15-.25.12-.5-.45-1.25-.24-.34-.53-.67-.72-.88-.77-.88-1.29-1.26-1.76-1.29h-.06c-.08,0-.15.02-.21.04l-.07.02c-.1.04-.2.1-.28.18-.08.08-.14.18-.19.29l-.02.06c-.07.2-.07.42.01.63.39,1.07,2.72,2.41,3.22,2.46h.06c.06,0,.1,0,.13-.02.14-.03.27-.13.34-.25Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m18.66,71.96l-4.52-1.18c-.23-.06-.46.04-.57.24l-.52.92H3.28c-.52,0-.94-.04-1.27-.14.04-.01.08-.02.13-.04.48-.11.83-.39,1.02-.79.27-.58.14-1.25-.06-1.66-.29-.58-1.12-.92-1.85-.75-.37.09-1.24.46-1.24,1.91,0,.7.22,1.27.66,1.7.54.53,1.37.8,2.48.8h15.38c.25,0,.47-.19.51-.45.03-.25-.13-.48-.37-.56Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m176.06,71.96l-4.52-1.18c-.23-.06-.46.04-.57.24l-.52.92H28.69l-.52-.91c-.11-.21-.35-.31-.57-.25l-4.52,1.18c-.24.07-.4.3-.37.56.03.25.25.45.51.45h152.7c.26,0,.48-.19.51-.45.03-.25-.13-.48-.37-.55Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m175.3,68.37h0c-.84-.65-1.33-.39-1.55-.17-.26.26-.33.62-.2.99.39,1.07,2.72,2.41,3.26,2.46.2,0,.4-.11.49-.27.4-.7-1.68-2.77-2-3.01Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m178.15,66.48c-.43,0-.68.22-.79.34-.41.48-.31,1.27-.15,2.17.31,1.86.43,2.25.96,2.25s.61-.44.85-1.78l.06-.36c.18-.99.29-1.79-.13-2.28-.13-.16-.38-.36-.79-.36Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m178.15,71.63c-.61,0-1.1.49-1.1,1.1s.49,1.11,1.1,1.11,1.11-.5,1.11-1.11-.5-1.1-1.11-1.1Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m182.8,69.19c.08-.21.08-.43.02-.62l-.03-.08c-.05-.11-.11-.2-.19-.29-.08-.08-.17-.14-.26-.17l-.09-.03c-.06-.01-.12-.02-.19-.03h-.05s-.03,0-.03,0c-.47.02-.99.41-1.76,1.28-.2.22-.48.55-.72.88-.57.75-.6,1-.45,1.25.07.12.2.22.33.24.04,0,.08.02.14.02h.06c.5-.04,2.84-1.38,3.22-2.46Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m197.72,68.56h0c-.73-.17-1.56.17-1.85.75-.2.4-.33,1.08-.06,1.65.19.41.54.68,1.02.8.05.01.1.02.13.04-.33.11-.76.16-1.26.15h-9.78s-.52-.92-.52-.92c-.11-.21-.35-.31-.57-.25l-4.52,1.18c-.25.07-.4.31-.37.56.04.25.25.44.51.44h15.38c1.11,0,1.94-.26,2.48-.79.44-.43.66-1,.66-1.7,0-1.45-.87-1.82-1.24-1.91Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m150.68,75.75H48.29c-.32,0-.58.26-.58.58s.26.58.58.58h102.39c.32,0,.58-.26.58-.58s-.26-.58-.58-.58Z"/>
                                    <polygon fill="#fff" strokeWidth="0px"
                                             points="96.41 102.12 95.29 99.24 93.25 99.24 93.25 99.5 94.12 99.5 95.5 102.72 94.23 105.82 93.3 105.82 93.3 106.08 94.81 106.08 95.98 103.19 96.96 106.08 99.03 106.08 99.03 105.82 98.1 105.82 96.83 102.7 98.18 99.5 98.21 99.5 99.09 99.5 99.09 99.24 97.68 99.24 96.41 102.12"/>
                                    <polygon fill="#fff" strokeWidth="0px"
                                             points="103.38 103.75 102.24 99.24 100.39 99.24 100.39 99.5 101.16 99.5 102.43 106.08 103.7 106.08 104.92 99.5 105.72 99.5 105.72 99.24 104.18 99.24 103.38 103.75"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m174.88,38.03c0-.32-.26-.58-.58-.58H24.67c-.32,0-.58.26-.58.58s.26.58.58.58h149.63c.32,0,.58-.26.58-.58Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m64.19,87.04c0-.07-.01-.15-.04-.21-.03-.07-.07-.13-.13-.19-.11-.11-.25-.17-.4-.17h-1.48c-.08,0-.15.01-.21.04-.08.03-.14.07-.2.13-.11.11-.17.25-.17.4,0,.07.01.14.04.21.03.08.07.14.13.2.05.05.12.1.19.13.07.03.14.04.21.04h.91v2.46s0,.09,0,.09c0,.02,0,.07-.03.14l-.1.25s-.03.06-.06.09c-.02.03-.05.06-.08.09-.07.07-.16.12-.27.16-.12.04-.26.06-.39.06h-.14s-.36-.16-.36-.16c-.03-.02-.05-.04-.07-.06-.03-.03-.05-.06-.07-.1-.03-.04-.05-.08-.07-.13-.02-.05-.04-.11-.06-.18-.04-.14-.06-.31-.06-.52,0-.07-.01-.14-.04-.21-.03-.07-.07-.14-.12-.19-.06-.06-.12-.1-.21-.14-.14-.05-.29-.05-.42,0-.07.03-.13.07-.18.12-.06.06-.1.12-.13.19-.03.07-.04.14-.04.22,0,.18.01.36.04.54.03.18.07.35.13.51.06.14.12.28.2.39.08.12.17.24.27.33.06.06.12.11.18.16.06.05.13.09.21.13l.18.08.34.1.39.04c.14,0,.28-.01.42-.03.14-.02.27-.06.39-.1.12-.04.25-.1.36-.17.11-.07.22-.15.31-.24.08-.07.15-.15.21-.23.06-.08.12-.17.16-.27l.17-.45.07-.36c.05-.7.04-1.48.02-2.23,0-.34-.01-.67-.01-.98Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m111.6,91.09c-.05-.05-.11-.09-.17-.11-.07-.03-.15-.05-.22-.05h-2.19v-3.87c0-.07-.02-.14-.05-.22-.03-.07-.07-.13-.11-.17-.06-.06-.13-.11-.2-.14-.14-.06-.31-.05-.44,0-.07.03-.14.08-.19.13-.05.05-.09.1-.12.17-.03.07-.04.14-.04.22v4.44c0,.08.01.15.05.23.03.06.06.12.12.18.05.05.11.09.19.12.07.03.14.04.22.04h2.77c.08,0,.15-.02.22-.05.06-.03.12-.07.16-.11.06-.06.1-.12.14-.19.03-.07.04-.15.04-.22s-.02-.15-.05-.22c-.03-.07-.08-.13-.13-.19Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m128.57,90.04c-.06-.1-.12-.18-.17-.26-.02-.02-.04-.05-.06-.07l.08-.06c.1-.08.2-.17.28-.26.08-.1.16-.21.22-.33.07-.12.12-.25.15-.37.03-.13.05-.26.05-.4s-.02-.26-.05-.39c-.03-.13-.08-.25-.15-.38-.07-.12-.14-.22-.22-.31-.08-.1-.17-.18-.28-.27-.1-.08-.21-.15-.33-.22-.12-.06-.24-.11-.37-.15-.13-.04-.26-.07-.4-.09-.13-.02-.28-.03-.4-.03h-.83c-.06-.01-.12,0-.18.02l-.35.02v.31c-.03.06-.04.13-.04.2v4.49c0,.08.02.15.04.22.03.07.07.13.12.18.05.05.12.1.19.13.13.05.29.05.44,0,.07-.03.14-.07.19-.13.11-.11.16-.25.16-.4v-1.34c.17,0,.39-.02.44-.01l.25.19s.07.08.11.13c.04.06.09.12.13.19.24.38.47.75.7,1.12.1.19.28.29.5.29.09,0,.18-.02.3-.08.06-.04.12-.09.16-.14.04-.06.08-.12.1-.19.02-.08.03-.16.02-.24-.01-.08-.04-.15-.08-.21-.23-.39-.47-.77-.72-1.15Zm-.61-1.63s-.02.07-.04.1c-.02.04-.05.08-.07.11-.03.04-.06.07-.1.1-.04.04-.09.07-.13.1-.05.03-.1.06-.16.08-.06.02-.12.05-.18.06-.06.01-.12.03-.16.03-.04,0-.09,0-.13,0h-.32s0-1.42,0-1.42h.25c.08,0,.16.01.23.02.07.01.14.03.21.05.07.02.13.05.19.08.05.03.11.06.15.1.04.03.08.07.12.11.03.04.06.08.09.12.04.07.06.15.06.23,0,.04,0,.07-.01.11Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m120.77,88.12c-.07-.17-.16-.33-.26-.48-.1-.15-.22-.29-.35-.42-.13-.13-.27-.25-.42-.35-.16-.1-.32-.19-.47-.25-.17-.07-.35-.13-.53-.17-.36-.08-.75-.08-1.12,0-.18.04-.36.09-.54.17-.17.07-.33.16-.48.26-.15.1-.29.22-.42.35-.13.13-.25.27-.34.42-.1.15-.19.31-.26.47-.07.18-.13.36-.16.53-.04.18-.06.37-.06.57s.02.38.05.56c.04.18.09.36.17.54.15.34.35.64.61.89.13.13.27.25.42.35.15.1.31.19.48.26.36.15.73.22,1.1.22.18,0,.37-.02.56-.06.18-.04.36-.1.53-.17.17-.07.33-.15.48-.25.15-.1.29-.22.42-.35.26-.26.47-.56.61-.9.07-.17.13-.36.17-.54.04-.18.05-.37.05-.56s-.02-.38-.05-.56c-.04-.18-.09-.36-.16-.53Zm-.93,1.1c0,.12-.01.23-.03.34-.02.1-.05.21-.1.31-.04.1-.1.2-.16.29-.06.09-.13.17-.2.25-.07.08-.16.14-.25.21-.09.06-.19.11-.29.16-.3.13-.66.17-.98.1-.1-.02-.21-.05-.31-.1-.1-.05-.2-.1-.29-.16-.09-.06-.17-.13-.25-.21-.08-.07-.14-.16-.2-.25-.06-.09-.12-.19-.16-.29-.09-.2-.13-.42-.13-.65,0-.12.01-.23.03-.34.02-.11.05-.21.1-.31.04-.1.1-.2.16-.29.06-.09.13-.17.2-.25.08-.08.16-.14.25-.21s.19-.12.29-.16c.2-.09.42-.13.64-.13.11,0,.23.01.34.03.1.02.2.05.31.1.1.05.2.1.29.16.09.06.17.13.24.2.15.15.27.33.36.54.04.1.08.21.1.31.02.11.03.22.03.34Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m138.3,91.17l-1.53-1.92,1.54-1.93c.04-.06.07-.12.09-.18.02-.08.03-.15.02-.23,0-.08-.03-.15-.08-.22-.04-.06-.09-.11-.14-.16-.12-.09-.26-.13-.41-.12-.08,0-.16.04-.23.08-.06.04-.12.09-.16.14l-1.36,1.7-1.36-1.7c-.05-.06-.1-.11-.16-.14-.07-.04-.15-.07-.23-.08-.15-.02-.29.02-.41.11-.06.04-.11.1-.15.17-.04.07-.06.14-.07.22,0,.08,0,.15.02.23.02.06.05.12.1.19l1.53,1.92-1.53,1.92c-.05.06-.08.12-.1.19-.02.07-.03.15-.02.22,0,.08.03.15.08.23.04.06.09.12.15.16.05.04.1.06.16.09.16.05.29.04.44-.03.08-.04.14-.09.19-.16l1.36-1.71,1.35,1.69c.05.08.12.14.21.18.08.04.17.06.25.06.06,0,.12,0,.19-.03.06-.02.11-.05.15-.08.06-.05.12-.1.16-.17.04-.07.07-.14.07-.22,0-.07,0-.15-.02-.23-.02-.07-.06-.13-.1-.19Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m56.57,87.73c-.06-.05-.12-.09-.18-.11-.02,0-.05-.02-.07-.02l.11-.32c.02-.07.03-.15.03-.23,0-.08-.03-.16-.06-.21-.07-.13-.17-.22-.33-.29-.15-.05-.3-.04-.45.04-.06.03-.12.08-.17.14-.05.06-.08.12-.1.19l-.22.67h-.43l.1-.3c.02-.07.03-.14.03-.21,0-.07-.02-.15-.05-.22-.06-.13-.16-.23-.33-.3-.15-.05-.3-.04-.45.04-.07.04-.12.08-.17.14-.05.06-.08.12-.11.19l-.22.67h-.35c-.07,0-.14.01-.22.04-.07.03-.13.07-.19.12-.11.11-.17.25-.17.41,0,.07.01.15.04.22.03.07.07.13.13.19.05.05.12.09.19.12.06.02.12.03.18.04l-.35,1.07h-.25c-.07,0-.15.01-.21.04-.07.03-.14.07-.19.12-.06.05-.1.12-.13.19-.03.07-.04.14-.04.21s.01.15.04.22c.02.06.06.11.1.16l-.35-.2.54.72v.04c-.06.15-.05.3.03.45.03.06.08.12.14.17.05.04.12.08.17.1.16.06.38.04.53-.07.1-.07.17-.17.21-.29l.21-.64.32.32c-.03.13-.02.27.04.39.04.07.08.13.15.19.06.05.13.08.19.1.06.02.12.03.18.03.12,0,.24-.04.34-.11.09-.06.16-.16.21-.28l.24-.73h.34c.07,0,.15-.01.22-.04.07-.03.13-.07.18-.12.11-.11.17-.25.17-.4,0-.07-.01-.14-.04-.22-.03-.07-.07-.13-.13-.19-.06-.06-.12-.1-.2-.12-.06-.02-.11-.03-.17-.04l.35-1.07h.24c.07,0,.14-.01.22-.04.06-.03.12-.06.17-.11.06-.06.11-.12.14-.19.03-.07.05-.15.05-.23s-.02-.16-.05-.22c-.03-.07-.08-.14-.14-.2Zm-1.83.99l-.35,1.07h-.43l.35-1.07h.43Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m146.65,86.42c-.14-.05-.29-.04-.43.03-.07.03-.13.07-.18.13-.05.05-.08.11-.12.18l-1.15,3.14-1.15-3.14c-.03-.06-.06-.12-.12-.18-.05-.05-.1-.09-.17-.12-.14-.06-.28-.07-.42-.02-.16.05-.27.16-.34.31-.03.07-.04.13-.05.2,0,.07,0,.15.03.22l1.7,4.64c.05.1.12.18.2.24.07.05.16.08.25.09l.08.21.08-.21c.09-.01.18-.04.25-.1.08-.06.15-.14.21-.26l1.69-4.61c.03-.07.04-.15.03-.22,0-.07-.02-.14-.05-.21-.07-.15-.18-.26-.34-.31Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m72.98,87.01c0-.08-.02-.16-.04-.21-.03-.07-.07-.13-.13-.19-.16-.16-.42-.2-.62-.12-.07.03-.14.07-.2.13-.11.11-.16.25-.16.4,0,.22,0,.55.02.93.02.9.06,2.27-.03,2.7-.01.02-.03.04-.05.06-.06.06-.15.12-.26.15-.3.09-.85.09-1.15,0-.12-.04-.21-.09-.31-.2-.11-.51-.07-1.76-.04-2.67.01-.38.02-.72.02-.97,0-.08-.02-.15-.05-.22-.03-.07-.08-.13-.13-.19-.23-.21-.59-.2-.8,0-.11.11-.17.25-.17.4v3.65s.04.22.04.22c.02.1.06.19.11.29.05.08.1.17.17.24.04.05.09.11.15.15.05.05.1.09.17.14l.35.19c.15.06.32.1.5.13.17.03.36.04.56.04s.38-.01.56-.04c.18-.03.35-.07.53-.14l.35-.19c.06-.04.11-.08.16-.13.05-.05.1-.1.15-.16l.1-.13c.09-.15.13-.24.19-.46.1-.68.07-1.76.04-2.71-.01-.4-.02-.77-.02-1.08Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m81.49,91.09c-.05-.05-.11-.09-.16-.11-.07-.03-.15-.05-.22-.05h-2.19v-3.87c0-.07-.01-.15-.05-.22-.03-.06-.07-.12-.11-.16-.06-.06-.13-.11-.2-.14-.14-.06-.32-.06-.44,0-.07.03-.14.08-.19.13-.05.05-.09.11-.12.17-.03.07-.05.14-.05.22v4.44c0,.08.02.15.05.23.03.06.07.12.12.18.05.05.11.09.19.12.07.03.14.04.21.04h2.77c.08,0,.15-.02.22-.05.06-.03.12-.07.17-.11.06-.06.1-.12.13-.19.03-.07.05-.15.05-.22s-.02-.16-.05-.22c-.03-.07-.08-.13-.13-.19Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m94.58,91.69c.02.06.04.11.08.17.04.06.1.11.17.15.05.03.1.05.2.07.11.02.16.01.31-.03.06-.02.11-.06.17-.11.05-.05.09-.11.12-.17.07-.13.08-.29.03-.43l-1.69-4.5c-.04-.12-.11-.22-.21-.28-.08-.05-.16-.08-.26-.09l-.07-.19-.07.19c-.09.01-.18.04-.26.1-.1.07-.17.17-.21.28l-1.68,4.48c-.03.08-.04.16-.04.22,0,.08.02.17.07.25.06.11.16.2.31.27.06.02.13.04.2.04.01,0,.02,0,.03,0,.06,0,.13-.02.2-.05.15-.06.25-.18.3-.32l.3-.8,1.96.68.04.09Zm-1.59-1.84l.45-1.19.58,1.55-1.03-.36Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m100.71,92.03c.07-.03.13-.06.18-.12.12-.11.18-.25.18-.41v-1.88h2.35c.08,0,.15-.02.23-.05.07-.03.13-.07.18-.12.11-.11.17-.25.17-.4,0-.08-.02-.16-.05-.23-.03-.07-.08-.13-.13-.18-.11-.1-.24-.15-.39-.15h-2.35v-.92h2.35c.07,0,.15-.01.21-.04.06-.03.13-.06.18-.12.12-.11.18-.25.18-.42,0-.08-.02-.16-.05-.23-.03-.07-.08-.13-.13-.18-.11-.1-.24-.15-.39-.15h-2.92c-.07,0-.14.01-.21.04-.07.03-.13.07-.2.13-.11.11-.17.25-.17.4v4.51c0,.08.01.15.04.21.03.07.07.13.12.19.11.11.25.17.41.17.08,0,.15-.01.21-.04Z"/>
                                    <path fill="#fff" strokeWidth="0px"
                                          d="m86.63,86.63c-.06-.06-.13-.11-.2-.14-.14-.06-.29-.06-.45,0-.07.03-.14.08-.19.14-.04.05-.08.11-.11.17-.03.07-.04.14-.04.21v4.49c0,.07.01.14.04.21.02.06.06.11.11.18.11.12.25.18.41.18.07,0,.15-.01.22-.04.08-.03.15-.08.21-.14.05-.05.08-.11.11-.17.03-.07.04-.14.04-.21v-4.49c0-.07-.01-.14-.04-.21-.02-.06-.06-.12-.11-.18Z"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                anchor="left"
                open={state}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {list()}
            </SwipeableDrawer>
            <Outlet/>
        </div>
    )
}

export default Root