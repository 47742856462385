import CircularProgress from "@mui/material/CircularProgress";

const GuestLoading = () => {

    return (
        <div className="mt-24 flex flex-col gap-y-2 items-center justify-center">
            <CircularProgress
                sx={{
                    '&.MuiCircularProgress-root': {
                        color: '#a4a1c1'
                    }
                }}/>
            <div className="text-gray-700">Pesquisando convidado...</div>
        </div>
    )
}

export default GuestLoading