import Guest from "../models/Guest";
import {addDoc, getDoc, doc, collection, query, Timestamp, where, runTransaction} from "firebase/firestore";
import {db} from '../config/firebase'
import CheckIn from "../models/CheckIn";
import {useNavigate} from "react-router-dom";
import {enqueueSnackbar} from "notistack";
import {Checkbox, CircularProgress, FormControlLabel, FormGroup} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React, {useState} from "react";

interface Props {
    guest: Guest
}

const CheckInButton = ({guest}: Props) => {
    const [processing, setProcessing] = useState(false)
    const [physicalGift, setPhysicalGift] = useState(false)
    const navigate = useNavigate()

    const checkIn = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        setProcessing(true)

        const request = {} as CheckIn
        request.guest = guest
        request.timestamp = Timestamp.now()

        try {
            await runTransaction(db, async (transaction) => {
                await addDoc(collection(db, "check-ins"), request)

                const guestRef = doc(db, "guests", `${guest.id}`)
                const documentSnapshot = await transaction.get(guestRef)
                if (!documentSnapshot.exists()) {
                    throw "Document does not exist!";
                }

                transaction.update(guestRef, { checkedIn: true, checkedInAt: Timestamp.now(), physicalGift })

                navigate('/check-in/success', {state: { guestName: guest.guestName }})
            })
        } catch (e) {
            console.error("Error adding document: ", e);
        } finally {
            setProcessing(true)
        }
    }

    return (
        <div className="flex flex-col gap-y-2">
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={physicalGift} onChange={(event, checked) => setPhysicalGift(checked)} />} label="Trouxe presente" />
            </FormGroup>

            <LoadingButton
                type="button" onClick={(e) => checkIn(e)}
                loading={processing}
                loadingIndicator={<CircularProgress className="h-6 w-6 text-gray-900"/>}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disableElevation
                className="rounded-full normal-case bg-gradient-to-tr from-green-500 to-green-400 text-white font-bold py-2.5 text-xl sm:text-lg mt-8 sm:mb-6">
                Fazer check-in
            </LoadingButton>
        </div>
    )
}

export default CheckInButton