import {useEffect, useState} from "react";
import Guest from "../models/Guest";
import CheckIn from "../models/CheckIn";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from "../config/firebase";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from "@mui/material/CircularProgress";

const CheckInList = () => {
    const [checkIns, setCheckIns] = useState<CheckIn[]>([])
    const [isLoading, setLoading] = useState<boolean>()

    const fetchCheckIns = async () => {
        setLoading(true)

        let collectionRef = collection(db, "check-ins")

        const q = query(collectionRef, orderBy('guest.guestName', 'asc'))

        const querySnapshot = await getDocs(q)

        const newCheckIns: CheckIn[] = []
        querySnapshot.forEach((doc) => {
            newCheckIns.push(doc.data() as CheckIn)
        })
        setCheckIns(newCheckIns)

        setLoading(false)
    }

    useEffect(() => {
        fetchCheckIns()
    }, [])

    return (
        <div className="py-6 px-4">
            <h1 className="text-primary-800 text-3xl font-bold mb-4">
                Lista de check-ins
            </h1>

            {isLoading ? (
                <div className="py-10 flex justify-center">
                    <CircularProgress
                        sx={{
                            '&.MuiCircularProgress-root': {
                                color: '#a4a1c1'
                            }
                        }}/>
                </div>
            ) : (
                <TableContainer component={Paper} className="rounded-md border">
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Convidado</TableCell>
                                <TableCell>Origem</TableCell>
                                <TableCell>Chegada</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {checkIns.map((row) => (
                                <TableRow
                                    key={row.guest.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.guest.guestName}
                                    </TableCell>
                                    <TableCell>
                                        {row.guest.source}
                                    </TableCell>
                                    <TableCell>{new Intl.DateTimeFormat('pt-BR', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                    }).format(row.timestamp.toDate())}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    )
}

export default CheckInList