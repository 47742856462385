import React, {
    createContext,
    JSXElementConstructor,
    ReactElement,
    ReactNode,
    useEffect,
    useReducer,
    useState
} from 'react';
import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    TwitterAuthProvider,
    User,
} from 'firebase/auth';
import {auth} from "../config/firebase";

interface State {
    isAuthenticated: boolean,
    isInitialized: boolean,
    user: User | null
}

const initialState: State = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const reducer = (state: any, action: any) => {
    if (action.type === 'INITIALISE') {
        const {isAuthenticated, user} = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    } else if (action.type === 'UPDATE_USER') {
        const {user} = action.payload;
        return {
            ...state,
            user,
        };
    }

    return state;
};

const AuthContext = createContext({
    ...initialState,
    method: 'firebase',
    login: (email: string, password: string) => Promise.resolve(),
    signInWithGoogle: () => Promise.resolve(),
    signInWithTwitter: () => Promise.resolve(),
    register: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    updateUser: (user: User) => {
    }
})

const AuthProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const [profile, setProfile] = useState<User | null>(null)

    useEffect(
        () =>
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    try {
                        setProfile(user)

                        dispatch({
                            type: 'INITIALISE',
                            payload: {isAuthenticated: true, user},
                        })
                    } catch (e: any) {
                        dispatch({
                            type: 'INITIALISE',
                            payload: {isAuthenticated: false, user: null},
                        })
                    }
                } else {
                    dispatch({
                        type: 'INITIALISE',
                        payload: {isAuthenticated: false, user: null},
                    })
                }
            }),
        [dispatch]
    );

    const login = (email: string, password: string) => signInWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
        const user = userCredential.user

        // if (user) {
        //     await authService._processAfterSignIn(user);
        // }
    });

    const signInWithGoogle = () => signInWithPopup(auth, new GoogleAuthProvider())
        .then(async (userCredential) => {
            const user = userCredential.user

            // if (user) {
            //     await authService._processAfterSignIn(user);
            // }
        })
        .catch((error) => {
            // authService._processErrorOnSignIn(error)
        });

    const signInWithTwitter = () => signInWithPopup(auth, new TwitterAuthProvider())
        .then(async (userCredential) => {
            const user = userCredential.user

            if (user) {
                // await authService._processAfterSignIn(user);
            }
        })
        .catch((error) => {
            // authService._processErrorOnSignIn(error)
        });

    const register = (email: string, password: string, firstName: string, lastName: string) =>
        createUserWithEmailAndPassword(auth, email, password).then(async (res) => {

        });

    const logout = async () => {
        dispatch({
            type: 'INITIALISE',
            payload: {isAuthenticated: false, user: null},
        })
        await signOut(auth)
    }

    const updateUser = (user: User) => dispatch({type: 'UPDATE_USER', payload: {user}})

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'firebase',
                user: profile,
                login,
                signInWithGoogle,
                signInWithTwitter,
                register,
                logout,
                updateUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export {AuthContext, AuthProvider};
