import {useEffect, useState} from "react";
import {collection, query, where, getDocs, getCountFromServer} from "firebase/firestore";
import {db} from '../config/firebase'
import Guest from "../models/Guest";
import CheckInButton from "./CheckInButton";
import Home from "./Home";
import {useParams} from "react-router-dom";
import GuestLoading from "./GuestLoading";
import useAuth from "../hooks/useAuth";
import {parsePhoneNumber} from "libphonenumber-js";

const PartyGuest = () => {
    let {keyword, } = useParams()
    const {isAuthenticated} = useAuth()

    const [times, setTimes] = useState<number>(0)
    const [guest, setGuest] = useState<Guest>()
    const [isLoading, setLoading] = useState<boolean>()

    const fetchGuest = async () => {
        setLoading(true)

        console.log('keyword')
        console.log(keyword)
        console.log(decodeURI(keyword!))

        let guestsCollection = collection(db, "guests");

        const q = query(guestsCollection, where('secretWord', '==', keyword))

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            setGuest(doc.data() as Guest)

            fetchTimes()
        }

        setLoading(false)
    }

    const fetchTimes = async () => {
        let checkInsCollection = collection(db, "check-ins");

        const q = query(checkInsCollection, where('guest.secretWord', '==', keyword))

        const querySnapshot = await getCountFromServer(q);
        setTimes(querySnapshot.data().count)
    }

    useEffect(() => {
        if (keyword) {
            fetchGuest()
        }
    }, [])

    if (!keyword) {
        return <Home/>
    }

    if (isLoading) return <GuestLoading/>

    return (
        <div className="text-left p-6 text-gray-900">
            {!!guest ? (
                <div>
                    <div className="text-lg leading-8">
                        <h1 className="text-primary-800 text-3xl font-bold">
                            Convidado
                        </h1>

                        <div><span className="font-medium">Nome:</span> {guest.guestName}</div>
                        <div><span className="font-medium">Palavra chave:</span> {guest.secretWord}</div>
                        <div><span className="font-medium">Celular:</span> {parsePhoneNumber(guest.mobilePhone, 'BR').formatNational()}</div>
                        <div className="font-bold">Setor: {guest.sector}</div>
                    </div>

                    {isAuthenticated ? (
                        <>
                            {times > 0 && (
                                <div className="text-red-500 font-bold mt-4">Esse convidado já fez check-in {times} vez(es).</div>
                            )}

                            <CheckInButton guest={guest}/>
                        </>
                    ) : (
                        <>
                            <div className="mt-4">Apresente o convite com o QR Code na recepção da festa.</div>
                            <div className="mt-4">O endereço e mais informações você encontra em <a className="text-primary-500" href="https://juliaflor.com/">juliaflor.com</a></div>
                        </>
                    )}
                </div>
            ) : (
                <div>
                <h1 className="text-3xl font-bold underline text-red-300">
                        Esse código não está cadastrado na lista de convidados.
                    </h1>
                </div>
            )}
        </div>
    )
}

export default PartyGuest