import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {SnackbarProvider} from "notistack";
import CheckInSuccess from "./components/CheckInSuccess";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CheckInList from "./components/CheckInList";
import Root from "./components/Root";
import {AuthProvider} from "./context/FirebaseContext";
import AuthGuard from "./guards/AuthGuard";
import GuestsList from "./components/GuestsList";

const router = createBrowserRouter([
    {
        element: <Root/>,
        children: [
            {
                path: "/",
                element: <AuthGuard><App/></AuthGuard>
            },
            {
                path: "guest/:keyword",
                element: <App/>
            },
            {
                path: "check-in/success",
                element: <AuthGuard><CheckInSuccess/></AuthGuard>
            },
            {
                path: "check-ins",
                element: <AuthGuard><CheckInList/></AuthGuard>
            },
            {
                path: "guests",
                element: <AuthGuard><GuestsList/></AuthGuard>
            },
        ]
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <SnackbarProvider anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}/>
        <AuthProvider>
            <RouterProvider router={router}/>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
