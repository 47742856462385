import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from "react";

const CheckInSuccess = () => {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <div className="p-6">

            <div className="overflow-y-auto overflow-x-hidden z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">
                <div className="relative text-center p-4 w-full max-w-md h-full md:h-auto">

                    <CheckCircleIcon className="w-14 h-14 rounded-full text-green-500 mb-4" />

                    <p className="text-lg font-semibold text-green-500">Check-in realizado com sucesso!</p>
                    {location.state && location.state.guestName && <p className="mb-4 text-lg font-semibold text-gray-900">{location.state.guestName}</p>}

                    <Button
                        type="button" onClick={() => navigate('/')}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        disableElevation
                        className="rounded-full normal-case bg-gradient-to-tr from-primary-500 to-primary-400 text-white font-bold py-2.5 text-xl sm:text-lg mt-8 sm:mb-6">
                        Novo check-in
                    </Button>
                </div>
            </div>

        </div>
    )
}

export default CheckInSuccess;