import React, {useEffect, useState} from "react";
import Guest from "../models/Guest";
import CheckIn from "../models/CheckIn";
import {
    collection,
    getDocs,
    query,
    where,
    orderBy,
    getCountFromServer,
    CollectionReference,
    DocumentData,
    Query
} from "firebase/firestore";
import {db} from "../config/firebase";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from "@mui/material/CircularProgress";
import {parsePhoneNumber} from "libphonenumber-js";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {ReactComponent as GiftIcon} from '../assets/icons/gift.svg';

const GuestsList = () => {
    const [guests, setGuests] = useState<Guest[]>([])
    const [filter, setFilter] = useState<string>('all')
    const [guestsCount, setGuestsCount] = useState<number>()
    const [guestsWhoArrivedCount, setGuestsWhoArrivedCount] = useState<number>()
    const [isLoading, setLoading] = useState<boolean>()

    const fetchGuests = async () => {
        setLoading(true)

        await calculateArrivedGuests()

        setFilter('all')
        await executeFilter('all')

        setLoading(false)
    }

    async function calculateArrivedGuests() {
        const collectionRef = collection(db, "guests")

        const guestsAggregateQuerySnapshot = await getCountFromServer(collectionRef)

        setGuestsCount(guestsAggregateQuerySnapshot.data().count)

        const guestsWhoArrivedQuery = query(collectionRef, where('checkedIn', '==', true));
        const aggregateQuerySnapshot = await getCountFromServer(guestsWhoArrivedQuery)

        setGuestsWhoArrivedCount(aggregateQuerySnapshot.data().count)
    }

    async function updateList(q: Query<any, DocumentData>) {
        const querySnapshot = await getDocs(q)

        const newGuests: Guest[] = []
        querySnapshot.forEach((doc) => {
            newGuests.push(doc.data() as Guest)
        })
        setGuests(newGuests)
    }

    async function executeFilter(filter: string) {
        const collectionReference = collection(db, "guests")

        const filterQueries: Record<string, ReturnType<typeof query>> = {
            'all': query(collectionReference, orderBy('guestName', 'asc')),
            'checked-in': query(collectionReference, where('checkedIn', '==', true))
        }

        const q = filterQueries[filter]

        if (!q) {
            console.error('Invalid filter:', filter)
            return
        }

        try {
            await updateList(q)
        } catch (error) {
            console.error('Error updating list:', error);
        }
    }

    useEffect(() => {
        fetchGuests()
    }, [])

    return (
        <div className="py-6 px-4">
            <h1 className="text-primary-800 text-3xl font-bold">
                Lista de convidados
            </h1>

            {isLoading ? (
                <div className="py-10 flex justify-center">
                    <CircularProgress
                        sx={{
                            '&.MuiCircularProgress-root': {
                                color: '#a4a1c1'
                            }
                        }}/>
                </div>
            ) : (
                <>
                    <div className="text-primary-600 mb-4">
                        {guestsWhoArrivedCount} de {guestsCount} convidados já chegaram.
                    </div>

                    <TableContainer component={Paper} className="rounded-md border">
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="flex items-center justify-between">
                                        <div className="text-base">Convidado</div>

                                        <select value={filter}
                                                onChange={async e => {
                                                    setLoading(true)
                                                    setFilter(e.target.value)
                                                    await executeFilter(e.target.value)
                                                    setLoading(false)
                                                }}
                                                className="rounded-md border- border-gray-300 py-0.5 px-2 focus:outline-0">
                                            <option value="all">Todos</option>
                                            <option value="checked-in">Quem chegou</option>
                                        </select>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {guests.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            <div className="flex flex-col">
                                                <div className="flex items-center gap-x-2"><span
                                                    className="font-medium">Nome:</span> {row.guestName}
                                                    {row.physicalGift && <GiftIcon className="w-3.5 h-3.5 mb-0.5 text-pink-500"/>}
                                                    {row.checkedIn &&
                                                        <div className="text-green-500 text-xs"><CheckCircleIcon
                                                            className="w-4 h-4 mb-0.5"/> {new Intl.DateTimeFormat('pt-BR', {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            second: '2-digit'
                                                        }).format(row.checkedInAt.toDate())}</div>}
                                                </div>
                                                <div><span
                                                    className="font-medium">Palavra chave:</span> {row.secretWord}</div>
                                                <div>
                                                    {row.mobilePhone && row.mobilePhone != 'N/A' ? (
                                                        <>
                                                            <span className="font-medium">Celular:</span>
                                                            <span>{parsePhoneNumber(row.mobilePhone, 'BR').formatNational()}</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="font-medium">Celular:</span>
                                                            <span>N/A</span>
                                                        </>
                                                    )}
                                                </div>
                                                <div><span className="font-medium">Origem:</span> {row.source}</div>
                                                <div><span className="font-medium">Setor:</span> {row.sector}</div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </div>
    )
}

export default GuestsList