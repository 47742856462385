import {ReactNode, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import LoadingScreen from '../components/LoadingScreen'
import LoginForm from "../components/auth/login/LoginForm";

interface AuthGuardProps {
    children: ReactNode
}

const AuthGuard = ({children}: AuthGuardProps) => {
    const {isAuthenticated, isInitialized} = useAuth()
    const {pathname} = useLocation()
    const [requestedLocation, setRequestedLocation] = useState<string | null>(null)

    if (!isInitialized) {
        return <LoadingScreen isDashboard={false}/>
    }

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <LoginForm/>
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation}/>
    }

    return <>{children}</>
}

export default AuthGuard
