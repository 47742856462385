import React from 'react';
import logo from './logo.svg';
import './App.css';
import PartyGuest from "./components/PartyGuest";

function App() {



  return (
    <div className="App">

      <PartyGuest />

      {/*<header className="App-header">*/}
      {/*  <img src={logo} className="App-logo" alt="logo"/>*/}
      {/*  <p>*/}
      {/*    Beatriz Campana Granjo*/}
      {/*  </p>*/}
      {/*  <p>*/}
      {/*    55 11 97550-8869*/}
      {/*  </p>*/}
      {/*  <a*/}
      {/*      className="App-link"*/}
      {/*      href="https://julias-birthday.com"*/}
      {/*      target="_blank"*/}
      {/*      rel="noopener noreferrer"*/}
      {/*  >*/}
      {/*    Check-in*/}
      {/*  </a>*/}
      {/*</header>*/}
    </div>
  );
}

export default App;
