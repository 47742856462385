// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import {getAuth} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAG-0juxfLYtAm9vl-B89iQXz5RQIApygU",
    authDomain: "julias-birthday.firebaseapp.com",
    projectId: "julias-birthday",
    storageBucket: "julias-birthday.appspot.com",
    messagingSenderId: "67545216445",
    appId: "1:67545216445:web:467c94e726c79c9dd94ed4",
    measurementId: "G-V9MF0BMHW7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);