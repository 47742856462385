import {alpha, CircularProgress, OutlinedInputProps, styled, TextField, TextFieldProps} from "@mui/material";
import IconButton from "@mui/material/IconButton";

import {ReactComponent as EyeIcon} from '../../../assets/icons/eye.svg';
import React, {MouseEventHandler, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import {SubmitHandler, useForm} from "react-hook-form";
import {useSnackbar} from "notistack";
import ErrorIcon from '@mui/icons-material/Error';
import {AuthError} from "firebase/auth";
import {LoadingButton} from "@mui/lab";
import {classNames} from "../../../utils/utils";

interface FormData {
    email: string
    password: string
}

const LoginEmail = () => {
    const {login} = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const {enqueueSnackbar} = useSnackbar()
    const [processing, setProcessing] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const {control, setError, handleSubmit, register, formState} =
        useForm<FormData>({
            mode: 'onChange',
        })

    const {errors, isValid} = formState

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement | undefined>) => {
        event.preventDefault()
    }

    const onSubmit: SubmitHandler<FormData> = async (formData: FormData) => {
        if (processing) return
        try {
            setProcessing(true)

            await login(formData.email, formData.password)

            navigate('/', {replace: true})
        } catch (error: any) {
            _processErrorOnSignIn(error)
        } finally {
            setProcessing(false)
        }
    }

    const _processErrorOnSignIn = (error: AuthError) => {
        console.log(`An error happened. ${error}`)

        if (error.code) {
            switch(error.code) {
                case 'auth/email-already-in-use':
                    setError('email', {type: 'auth/email-already-in-use'})
                    break
                case 'auth/user-not-found':
                    setError('email', {type: 'auth/user-not-found'})
                    break
                case 'auth/wrong-password':
                    setError('password', {type: 'auth/wrong-password'})
                    break
                case 'auth/popup-closed-by-user':
                    break
                default:
                    enqueueSnackbar(error.message, {variant: "error"})
            }
        } else {
            enqueueSnackbar(error.message, {variant: "error"})
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}
              className="overflow-y-auto text-gray-900 flex flex-col items-center justify-center py-8 px-6 sm:px-10 mx-auto max-w-[500px]">
            <h1 className="w-full text-3xl font-bold">
                Faça login na sua conta
            </h1>
            <div className="w-full mb-6 text-gray-400">Esse login é destinado à recepção da festa</div>

            <div className="w-full">
                <label htmlFor="email" className="block font-medium leading-6 text-gray-900">
                    Email
                </label>
                <div className="relative mt-1 rounded-md shadow-sm w-full">
                    <input
                        type="email"
                        {...register('email', {
                            required: true,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                        })}
                        autoComplete="off"
                        className={classNames(errors.email ? 'border-red-500 text-red-500 z-60' : '', 'py-2 px-3 text-lg block w-full rounded-md border-gray-300 text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-4 focus:ring-primary-200 sm:leading-6 focus:border-primary-400 focus:ring-opacity-50')}
                        placeholder=""
                    />
                    {errors.email ? (
                        <div
                            className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ErrorIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                        </div>
                    ) : null}
                </div>
                <div className="text-xs text-red-500 mt-1">
                    {errors.email && errors.email.type === 'pattern' ? (
                        <span>please_enter_a_valid_email</span>
                    ) : null}

                    {errors.email && errors.email.type === 'required' ? (
                        <span>enter_the_email</span>
                    ) : null}

                    {errors.email && errors.email.type === 'auth/user-not-found' ? (
                        <span>email_not_found</span>
                    ) : null}
                </div>
            </div>

            <div className="w-full mt-4">
                <label htmlFor="password" className="block font-medium leading-6 text-gray-900">
                    Senha
                </label>
                <div className="relative mt-1 rounded-md shadow-sm w-full flex">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        {...register('password', {
                            required: true,
                        })}
                        autoComplete="off"
                        className={classNames(errors.password ? 'border-red-500 text-red-500 z-60' : '', 'py-2 px-3 text-lg block w-full rounded-md border-gray-300 text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-4 focus:ring-primary-200 sm:leading-6 focus:border-primary-400 focus:ring-opacity-50')}
                        placeholder=""
                    />
                    {errors.password ? (
                        <div
                            className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ErrorIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                        </div>
                    ) : (
                        <IconButton
                            size="small"
                            className="absolute inset-y-0 right-0 flex items-center h-[46px] w-[46px] sm:h-[42px] sm:w-[42px]"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            disableRipple
                            edge="end"
                        >
                            {showPassword ? <EyeIcon className="w-4 text-gray-900 opacity-50"/> :
                                (
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                         stroke="currentColor" className="w-5 text-gray-900">
                                        <path
                                            d="M2.8 7.8c2.1 1 4.5 1.6 7 1.6s4.9-.6 7-1.6m-7 2v3M5.1 9.2l-1.5 2.6m11-2.6 1.5 2.6"
                                            opacity=".5"></path>
                                    </svg>
                                )}
                        </IconButton>
                    )}
                </div>
                <div className="text-xs text-red-500 mt-1">
                    {errors.password && errors.password.type === 'required' ? (
                        <span>Digite sua senha.</span>
                    ) : null}

                    {errors.password && errors.password.type === 'auth/wrong-password' ? (
                        <span>Ops! Senha incorreta.</span>
                    ) : null}
                </div>
            </div>

            {/*<div className="text-sm w-full mt-2">*/}
            {/*    <Link to={PATH_AUTH.resetPassword}*/}
            {/*          className="font-medium text-gray-700 hover:text-gray-900 hover:underline">*/}
            {/*        forgot_password*/}
            {/*    </Link>*/}
            {/*</div>*/}

            <LoadingButton
                type="submit"
                disabled={!isValid}
                loading={processing}
                loadingIndicator={<CircularProgress className="h-6 w-6 text-gray-900"/>}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disableElevation
                className="rounded-full normal-case bg-gray-900 disabled:bg-gray-200 disabled:text-gray-600 disabled:cursor-not-allowed text-white font-bold py-2.5 text-xl sm:text-lg mt-8 sm:mb-6">
                Entrar
            </LoadingButton>
        </form>
    )
}

export default LoginEmail;